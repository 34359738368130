import { createRouter, createWebHistory } from 'vue-router'
import uaParser from 'ua-parser-js'

const ua = uaParser();
const isMobile = ua && ua.os && ua.os.name && ((/iPhone|IOS|iPod|iPad|Silk|Android|BlackBerry|Opera Mini|IEMobile|MicroMessenger|Enjoyrv/i).test(ua.os.name));

const isWeChat = () => {
  if (ua) {
    const uas = JSON.stringify(ua);
    if (uas.indexOf("WeChat") > -1 || uas.indexOf("MicroMessenger") > -1) {
      return true;
    }
  }
  return false;
}

let path = isMobile ? 'mobile' : 'pc';

if (isWeChat()) {
  path = "error";
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/'+path+'/HomeView.vue'),
    meta: {
      title : '首页',
      keepAlive: true,
      showTab: true
    },
    children: [
      {
        path: '/',
        name: 'index',
        meta: {
          title : '首页',
          keepAlive: true,
          showTab: true,
          tabIndex: 0
        },
        component: () => import('@/views/'+path+'/IndexView.vue')
      },
      {
        path: 'item',
        name: 'item',
        meta: {
          title : '商品管理',
          keepAlive: true,
          showTab: true,
          tabIndex: 1
        },
        component: () => import('@/views/'+path+'/ItemView.vue')
      },
      {
        path: 'boss',
        name: 'boss',
        meta: {
          title : '老板管理',
          keepAlive: true,
          showTab: true,
          tabIndex: 2
        },
        component: () => import('@/views/'+path+'/BossView.vue')
      },
      {
        path: 'my',
        name: 'my',
        meta: {
          title : '我的',
          keepAlive: true,
          showTab: true,
          tabIndex: 3
        },
        component: () => import('@/views/'+path+'/MyView.vue')
      },
      {
        path: 'item-edit',
        name: 'itemEdit',
        meta: {
          title : '商品详情',
          keepAlive: false,
          showTab: false
        },
        component:()=>import('@/views/'+path+'/ItemEditView.vue')
      },
      {
        path: 'boss-edit',
        name: 'bossEdit',
        meta: {
          title : '老板详情',
          keepAlive: false,
          showTab: false
        },
        component:()=>import('@/views/'+path+'/BossEditView.vue')
      },
      {
        path: 'share',
        name: 'share',
        meta: {
          title : '分享管理',
          keepAlive: false,
          showTab: false
        },
        component:()=>import('@/views/'+path+'/ShareView.vue')
      },
      {
        path: 'bot',
        name: 'bot',
        meta: {
          title : 'BOT管理',
          keepAlive: false,
          showTab: false
        },
        component:()=>import('@/views/'+path+'/BotView.vue')
      },
      {
        path: 'chat',
        name: 'chat',
        meta: {
          title : 'Chat管理',
          keepAlive: false,
          showTab: false
        },
        component:()=>import('@/views/'+path+'/ChatView.vue')
      }
    ]
  },

  {
    path: '/public',
    name: 'publicHome',
    component: () => import('@/views/'+path+'/PublicHome.vue'),
    meta: {
      title : '分享首页',
      keepAlive: true,
      showTab: true
    },
    children: [
      {
        path: '',
        name: 'publicIndex',
        meta: {
          title : '公共-首页',
          keepAlive: true,
          showTab: true,
          tabIndex: 0
        },
        component: () => import('@/views/'+path+'/PublicIndex.vue')
      },
      {
        path: 'item',
        name: 'publicItem',
        meta: {
          title : '公共-商品',
          keepAlive: true,
          showTab: true,
          tabIndex: 1
        },
        component: () => import('@/views/'+path+'/PublicItemView.vue')
      },
      {
        path: 'other',
        name: 'publicOther',
        meta: {
          title : '公共-其他',
          keepAlive: true,
          showTab: true,
          tabIndex: 2
        },
        component: () => import('@/views/'+path+'/PublicOtherView.vue')
      },
      {
        path: 'item-info',
        name: 'publicItemInfo',
        meta: {
          title : '公共-商品详情',
          keepAlive: false,
          showTab: false
        },
        component: () => import('@/views/'+path+'/PublicItemInfoView.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title : '登录'
    },
    component:()=>import('@/views/'+path+'/LoginView.vue')
  },
  {
	path: '/:path(.*)',
	meta: {
      title : '404'
    },
	component:()=>import('@/views/NotFoundView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  if (isMobile) {
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, viewport-fit=cover, user-scalable=0';
    head[0].appendChild(meta);

    if (isWeChat()) {
      let div = document.getElementsByTagName('div');
      let h1 = document.createElement('h1');
      h1.innerHTML = '暂不支持微信浏览器访问, 请右上角选择在浏览器中打开';
      div[0].appendChild(h1);
    }
  }
  next()
});


router.afterEach((to, from) => {
});

export default router
