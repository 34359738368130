import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueClipBoard from 'vue-clipboard2'

import 'vant/lib/index.css'
import { Tabbar, TabbarItem, NavBar, Button, Icon, Rate, Cell, Image as VanImage, Search,
    Form, Field, CellGroup, Picker, Popup, Loading, PullRefresh, List, Row, Col, ActionSheet, DropdownMenu, DropdownItem, Switch, Grid, GridItem,
    Uploader, ImagePreview, Sticky, SwipeCell, Card, Overlay, Checkbox, CheckboxGroup, Dialog, Divider,
    Swipe, SwipeItem } from 'vant';

createApp(App).use(store).use(router)
    .use(VueClipBoard)
    .use(ElementPlus)
    .use(Tabbar).use(TabbarItem).use(NavBar).use(Button).use(Icon).use(Rate).use(Cell).use(VanImage).use(Search)
    .use(Form).use(Field).use(CellGroup).use(Picker).use(Popup).use(Loading).use(PullRefresh).use(List)
    .use(Row).use(Col).use(ActionSheet).use(DropdownMenu).use(DropdownItem).use(Switch).use(Grid).use(GridItem)
    .use(Uploader).use(ImagePreview).use(Sticky).use(SwipeCell).use(Card).use(Overlay).use(Checkbox).use(CheckboxGroup).use(Dialog).use(Divider)
    .use(Swipe).use(SwipeItem)
    .mount('#app');





